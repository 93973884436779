@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: #2d2d2d;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#page-container {
  padding: 10px;
}

#connect {
  color:rgb(16, 96, 28);
}

footer {
  color: white;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  text-decoration: none;
  background-color: white;
}

.malena-arduino-footer {
  margin-top: 20px;
}

main {
  flex-grow: 1;
}

h2 {
  color:rgb(16, 96, 28);
}

.italic-text {
  font-style: italic;
}

#lets-connect {
  margin-top: 50px;
}

header {
  background-color: white;
  padding: 30px;
  text-align: center;
  font-size: 35px;
  color: rgb(20, 110, 32);
}

.topnav {
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  background-color: white;

}

.topnav a {
  display: inline-block;
  text-decoration: none;
  color:rgb(16, 96, 28);
  text-align: center;
  padding: 14px;
  justify-content: center;
}

.topnav a:hover {
  background-color: rgb(16, 96, 28);
  color: white;
}

.boundary {
  margin: 100px;
  margin-top: 200px;
}


.col li {
  margin-bottom: 5px;
  list-style-position: outside;
  text-align: left;
  margin-left: 15px;
}

.title-label {
  font-weight: bold;
  font-size: 30px;
  background-color: whitesmoke;
  text-align: left;
  color: rgb(16, 96, 28);
  padding: 10px;

}

.education-text-title, .label.role {
  font-weight: bold;
}

.col-label-space {
  margin: 0.2rem;
}

.specific-skill {
  margin: 15px;
  background-color: lightgray;
  padding: 10px;
}

.badge {
  font-size: 15px;
  color: #2d2d2d;
  background: rgba(16, 96, 28, 0.123);
}

ul {
  padding-left: 0;
}

a.connect {
  padding-left: 0.5rem;
}

.col-6 {
  object-fit: cover;
}

.caption-quote {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.row.interest {
  display:flex;
  margin-top: 80px;
  margin-bottom: 80px;
}
.row>div{
  padding:15px;
}

.area .row:nth-child(2n+1){
  flex-direction:row-reverse
}

.interests-top-description {
  text-align: justify;
}

.text.col {
  text-align: justify;
}


.row.experience {
  margin: 1rem;
}

.badge {
  font-weight: 400;
}

.some-highlights {
  margin-top: 50px;
}

.row.gallery {
  display: flex;
}

img.gallery {
  width: 100%;
  margin-bottom: 10px;
}

.col.gallery {
  padding: 0 4px;
}

.btn.photography-gallery {
  background-color:  rgb(16, 96, 28);
  color: white;
}

.photoshop-title {
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (min-width: 1000px) {

  .title-label {
    font-size: 23px;
  }

  p.label.role {
    font-size: 18px;
  }

  .badge {
    font-size: 15px;
  }

  .col.education {
    margin-left: 2rem;
  }

  .col.certification {
    margin-left: 2rem;
  }

  .row.project {
    margin-left: 2rem;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {

  .title-label {
    font-size: 25px;
  }

  p.label.role {
    font-size: 20px;
  }

  .badge {
    font-size: 15px;
  }

  .col.education {
    margin-left: 1rem;
  }

  .col.certification {
    margin-left: 1rem;
  }

  .row.project {
    margin-left: 2rem;
  }
}

@media (min-width: 600px) and (max-width: 880px) {
  .boundary {
    margin: 30px;
    margin-top: 200px;
  }

  .title-label {
    font-size: 20px;
  }

  p.label.role {
    font-size: 20px;
  }

  .col-6 {
    width: 100%;
  }

  .about-img {
    height: 400px;
  }
  .badge {
    font-size: 15px;
  }

  .col.education {
    margin-left: 15px;
  }

  .col.certification {
    margin-left: 15px;
  }

  .row.project {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media (max-width: 600px) {

  .boundary {
    margin: 20px;
    margin-top: 200px;
  }

  #exp.col-4.text {
    width: 100%;
  }

  .col-5.text p {
    font-size: 18px;
  }

  p.label.role {
    font-size: 20px;
  }

  .row {
    display: block;
    padding: 0;
  }

  .title-label {
    font-size: 25px;
  }

  .col-4 {
    max-width: 100%;
  }

  .badge {
    display: block;
  }

  .skill.col.text {
    margin: 0;
  }

  .skill-list {
    margin: 0;
  }

  .col-6 {
    width: 100%;
  }

  .about-img {
    height: 300px;
  }
  
  .text.col {
    text-align: left;
  }

  .row.experience {
    margin-left: 0;
  }

  .col.education {
    margin-left: 15px;
  }

  .col.certification {
    margin-left: 15px;
  }

  .row.project {
    margin-left: 15px;
    margin-right: 15px;
  }
}